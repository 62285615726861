import React from 'react'

const links = [
  { url: 'https://parthshandilya.substack.com', label: 'Newsletter' },
  { url: 'https://www.parthshandilya.com/rss.xml', label: 'RSS' },
]

export const Footer = () => {
  return (
    <></>
  )
}
