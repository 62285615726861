import React from 'react'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'
import robot from '../assets/nav-robot.png'

export const Sidebar = () => {
  const socialLinks = [
    { url: 'https://parthshandilya.substack.com' },
    { url: 'https://github.com/parths007' },
    { url: 'https://www.linkedin.com/in/parths007' },
    { url: 'https://leetcode.com/parths007'},
    { url: 'https://www.parthshandilya.com/rss.xml' },
  ]

  return (
    <aside className="sidebar">
      <div className="sidebar-wrapper">
        <div className="sidebar-title">
          <Link to="/" className="sidebar-title-link">
            <span>
              <img
                src={robot}
                className="sidebar-logo"
                alt="Parth Shandilya"
                title="Parth Shandilya"
              />
            </span>
            <span>Parth Shandilya</span>
          </Link>
        </div>
        <div className="sidebar-container">
          <section className="sidebar-section">
            <h2>Current Focus</h2>
            <p>
              <ul style={{ fontSize: '14px', lineHeight: '1.5' }}>
                <li>
                  ML Engineer at{' '}
                  <a href="https://kadoa.com" target="_blank" rel="noreferrer">
                    Kadoa
                  </a>
                </li>
                <li>
                  Research at{' '}
                  <a
                    href="https://vlg.inf.ethz.ch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Computer Vision and Learning Group
                  </a>
                </li>
                <li>
                  MS in Computer Science at{' '}
                  <a
                    href="https://inf.ethz.ch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ETH Zurich
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://dmi.unibas.ch/de/studium/computer-science-informatik/master"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Uni. Basel
                  </a>
                </li>
              </ul>
            </p>
          </section>
          <section className="sidebar-section">
            <h2>Stay in touch</h2>
            <p>
              <a href="https://parthshandilya.substack.com" target="_blank" rel="noreferrer">
                Subscribe
              </a> {' '}
              to my newsletter for my latest writeups and connect on {' '}
              <a href="https://www.linkedin.com/in/parths007" target="_blank" rel="noreferrer">
                LinkedIn
              </a> {' '}
              for a quick chat.
            </p>
            {socialLinks.map((link) => (
              <SocialIcon
                target="_blank"
                key={link.url}
                url={link.url}
                fgColor="currentColor"
                bgColor="transparent"
                className="navbar-icon"
              />
            ))}
          </section>
        </div>
      </div>
    </aside>
  )
}
